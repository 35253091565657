import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import Box, { BoxTitle, BoxParagraph } from '../elements/box';
import Card, { CardImage } from '../elements/card';

const Therapie_en_groupe = () => (
  <Layout>
    <Box background="primary-light">
      <BoxTitle title="La Thérapie en Groupe" />
      <div className="columns">
        <div className="column">
          <Box height={true}>
            <div className="content justified-content">
              <p>Le travail en groupe apporte richesse et diversité, et permet l’exploration, <b>l’expérimentation concrète</b> de 
                notre façon d’être en relation avec autrui. Il aide à mieux comprendre certains modes de fonctionnement insatisfaisants ou 
                douloureux, et favorise l’émergence de nouveaux ajustements relationnels de façon complémentaire à la thérapie en 
                individuel. </p>
              <p>Il va permettre par exemple de traiter avec plus d’efficacité qu’une thérapie individuelle, des problèmes de timidité 
                ou d'inhibition dans les relations <em>(en groupe ou avec l'autre sexe)</em> - ou inversement, une centration excessive sur soi, 
                avec envahissement permanent du territoire des autres , ou encore de faire un travail corporel.</p>
              <p>Il peut par contre être très intéressant de reprendre en thérapie individuelle ce qui s’est passé pendant les sessions 
                de groupe, ou à l’inverse, préparer en individuel ce qui pourrait être travaillé en groupe.</p>
            </div>
          </Box>
        </div>
        <div className="column">
          <Box height={true}>
            <div className="content justified-content">
              <p>Le travail en groupe s’inscrit dans un processus de changement, le groupe constituant un outil précieux d’évolution 
                pour chacun. Ne serait-ce parfois qu’en sortant de la honte ou du sentiment d’être anormal parce qu’on se sent 
                accueilli par d’autres tel qu’on est, ou parce qu’on entend sa propre souffrance, ses inhibitions, ses ignorances, 
                ses incapacités, ses errements, ses répétitions, ses impuissances, etc. résonner chez les autres ou inversement, et 
                que tout cela reprend du coup place dans notre humanitude partagée...</p>
              <p>Il permet de contacter le semblable, donc, mais également l’altérité, à travers ces hommes et ces femmes tous différents.</p>
            </div>
            <Card>
              <CardImage src="main4" alt="main"/>
            </Card>
          </Box>
        </div>
        <div className="column">
          <Box height={true}>
            <div className="content justified-content">
              <p>Nous pourrons utiliser le psychodrame, « c'est jouer sa <em>(propre)</em> vie sur scène » dans un revécu affectif intense qui 
                « dégèle » les affects et amène la remontée des souvenirs bloqués, qui sont retravaillés <em>(l'interaction, les rôles, les 
                rêves)</em>. En psychodrame, il ne s'agit pas de jouer un rôle, comme au théâtre, mais d'être soi- même, de tenir son propre 
                rôle, d'être comme dans la vie, aussi authentique que possible et de « parler vrai » quitte à dire « en aparté́ » ce 
                qu'on ressent, ou le faire exprimer par un « double » ; ou surgir dans un « renversement des rôles » <em>(les co-acteurs 
                changeant de place et de rôle)</em>, ou dans une « projection dans le futur » <em>(ce qui pourrait advenir dans cinq 
                ou dix ans)</em>, ou dans un « surplus de réalité́ » en donnant une autre fin à une situation difficile comme, par exemple, 
                de dire au revoir à un parent mort et à qui on n'a pas pu parler avant sa fin, ce qui permettra de clore une situation, 
                de fermer la Gestalt et de « passer à autre chose ». </p>
            </div>
          </Box>
        </div>
      </div>
    </Box>
      <div className="column">
        <Card>
          <CardImage src="illustration2" alt="illustration"/>
        </Card>
      </div>
    <Box>
      <BoxTitle title="Les rêgles du travail de groupe" />
      <BoxParagraph>
        <p>Les règles de fonctionnement concernent chaque participant et le thérapeute en tant qu’animateur du groupe. Leur 
          apparente simplicité́ mérite cependant un bref commentaire : </p>
        <p><b><em>Expérimentation : </em></b>Le groupe est un laboratoire où chacun fait ses expériences et est invité à décrire 
          ses succès, doutes et échecs ainsi que les retentissements émotionnels qu’il vit.</p>
        <p><b><em>Présence : </em></b>Chacun est invité à vivre et exprimer ce qui se passe « ici et maintenant », y compris 
          lorsque c’est difficile, désagréable et lorsqu’il y a tentation d’être en contact avec l’« ailleurs » que ce soit en 
          imagination ou par le biais des téléphones portables.</p>
        <p><b><em>Émotions : </em></b>L’émotion est reconnue comme levier du changement. Son exploration est encouragée dans 
          le présent, et non à̀ des fins thérapeutiques (exploration visant la réparation de scenarii du passé).</p>
        <p><b><em>Feed-back : </em></b>Chacun est encouragé́ à donner ses propres sentiments aux personnes concernées dans 
          la situation présente.</p>
        <p><b><em>Discrétion : </em></b>Les propos et les émotions échangées ne doivent pas être divulgues à l’extérieur du 
          groupe. Par contre des décisions qui affectent le groupe entier sont travaillées pour être rapportées aux personnes 
          concernées à l’extérieur.</p>
        <p><b><em>Respect et non jugement </em></b>de l'autre.</p>
        <p><b><em>Mise en action et en parole au sein du groupe </em></b>, et non en dehors. Il s’agit 
          d’éviter les « bruits de couloir ».</p>
        <p><b><em>Annonce de son départ du groupe </em></b>lors de l’avant-dernière séance (importance de vivre les au revoir 
          et la séparation).</p>
        <p><b><em>Limites : </em></b>Chacun a droit à̀ dire NON ou STOP, 2 mots magiques, simplificateurs de vie, à condition 
          qu’ils soient posés en contact avec soi et le groupe.</p>
        <p><b><em>Choix : </em></b>Dire oui à̀ quelqu’un, à une proposition, à une place à prendre dans le groupe. Faire un 
          choix, c’est d’abord dire oui, mais c’est aussi dire non à̀ toutes les autres possibilités.</p>
      </BoxParagraph>
    </Box>
    <Box background="primary-light">
      <BoxTitle title="Plusieurs formules pour les groupes de thérapie :" />
      <div className="columns">
        <div className="column">
          <Box>
            <BoxParagraph>
              <p><b>Groupe de 4 personnes</b></p>
              <p>un soir par mois, de 18h à 22h</p>
            </BoxParagraph>
          </Box>
        </div>
        <div className="column">
          <Box>
            <BoxParagraph>
              <p><b>Groupe de 6 à 8 personnes</b></p>
              <p>un jour par mois, de 9h à 18h</p>
            </BoxParagraph>
          </Box>
        </div>
      </div>
      <Link to='/groupes_residentiels'>
        <Box>
          <div className="columns">
          <div className="column is-5">
              <Card height="" >
                <CardImage src="lanzarote1" alt="lanzarote" hoverable="true" />
              </Card>
            </div>
            <div className="column">
              <BoxParagraph>
                <p><b>Groupe de 8 personnes en résidentiel</b></p>
                <p>Une semaine sur l'île de Lanzarote</p>
                <p><em>(Cliquez sur cette box pour plus d'informations)</em></p>
              </BoxParagraph>
                <div className="columns">
                  <div className="column"></div>
                    <div className="column is-narrow">
                      <Card height="">
                        <CardImage src="logo_lanzarote" alt="logo lanzarote" size="is-128x128" hoverable="true" />
                      </Card>
                    </div>
                    <div className="column"></div>
                </div>
            </div>
          </div>
        </Box>
      </Link>
    </Box>      
  </Layout>
)

export default Therapie_en_groupe;